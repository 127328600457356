import { Router } from '@reach/router'
import React from 'react'

import Route from '@fundrocket/common-web/components/Route'

import paths from 'constants/paths'
import SignIn from 'app/pages/SignIn'

export default function SignInPage() {
  return (
    <Router>
      <Route component={SignIn} path={paths.SIGN_IN} default />
      <Route component={SignIn} path={`${paths.SIGN_IN}:blid`} />
    </Router>
  )
}
