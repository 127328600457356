import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import FieldWithInput from '@fundrocket/common-web/components/FieldWithInput'
import assertions from '@fundrocket/common-web/libs/assertions'

export default function FieldEmail({ inputProps, optional, ...props }) {
  return (
    <FieldWithInput
      {...props}
      inputProps={{
        ...inputProps,
        autoComplete: 'email',
        placeholder: 'name@email.com',
        type: 'email',
        value: props.value || get(inputProps, 'value'),
        testid: 'email-input',
      }}
      optional={optional}
      validate={(e) => {
        // TODO
        // assertions.required(e.target.value)
        if (!optional) {
          assertions.required(e.target.value)
        }
        assertions.email(e.target.value)
      }}
      className="FieldEmail"
    />
  )
}

FieldEmail.propTypes = {
  label: PropTypes.string,
  optional: PropTypes.bool,
  value: PropTypes.string,
}

FieldEmail.defaultProps = {
  inputProps: {},
  label: 'Email',
}
