import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import TextMask from 'react-text-mask'

import cursorMaskHandler from '@fundrocket/common-web/libs/cursor-mask-handler'

import styles from './InputPhoneNumber.module.scss'

export default function InputPhoneNumber({ className, inputRef, ...others }) {
  return (
    <TextMask
      {...others}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholder="(555) 555-5555"
      type="tel"
      className={classNames('InputPhoneNumber', styles.this, className)}
      onClick={cursorMaskHandler('-')}
    />
  )
}

InputPhoneNumber.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.func,
}
