import React from 'react'

import Container from '@fundrocket/common-web/components/Container'

import SignInForm from 'components/SignInForm'
import Utility from 'templates/Utility'

export default function SignIn(props) {
  return (
    <Utility title="Sign in" className="SignIn">
      <Container flush size="small">
        <SignInForm {...props} />
      </Container>
    </Utility>
  )
}
