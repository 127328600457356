import classNames from 'classnames'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'
import Alert from 'react-s-alert'

import inputValues from '@fundrocket/common/constants/input-values'
import formatPhone from '@fundrocket/common/libs/format-phone'
import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import verifyPhone from '@fundrocket/common/libs/verify-phone'
import Button from '@fundrocket/common-web/components/Button'
import FieldEmail from '@fundrocket/common-web/components/FieldEmail'
import Fieldset from '@fundrocket/common-web/components/Fieldset'
import FieldWithInput from '@fundrocket/common-web/components/FieldWithInput'
import Input from '@fundrocket/common-web/components/Input'
import InputPhoneNumber from '@fundrocket/common-web/components/InputPhoneNumber'
import Text from '@fundrocket/common-web/components/Text'
import assertions from '@fundrocket/common-web/libs/assertions'
import notifications from '@fundrocket/common-web/libs/notifications'

import Link from 'components/Link'
import events from 'constants/events'
import paths from 'constants/paths'
import { UserContext } from 'contexts/User'
//import { withWomplyUserContext } from 'contexts/WomplyUserContext'

const STATUS_EMAIL = 'email'
const STATUS_PHONE = 'phone'
const STATUS_PHONE_VERIFICATION = 'phone-verification'

class SignInForm extends React.Component {
  static contextType = UserContext

  state = {
    email: '',
    emailValid: null,
    phone: '',
    phoneLast4: '',
    phoneVerificationCode: '',
    phoneVerificationResendCodeCount: 0,
    signInLoading: false,
    status: STATUS_PHONE,
  }

  queryString

  redirectPath = paths.DASHBOARD

  userExists = false

  componentDidMount() {
    const { search: queryString } = window.location

    this.queryString = qs.parse(queryString, { ignoreQueryPrefix: true })

    this.getAuthStatus()
  }

  getAuthStatus = async () => {
    const verifyStatus = await verifyPhone.status()

    if (verifyStatus) {
      this.context.updateUser({ user: verifyStatus })
      this.navigate()
    } else {
      this.handleData()
    }
  }

  handleData = async () => {
    if (!this.queryString) return

    const { email, phone, redirect } = this.queryString

    if (redirect) this.redirectPath = redirect

    if (!phone) return

    this.setState({ phone: formatPhone.display(phone) })

    if (email) {
      this.setState(
        {
          status: STATUS_PHONE_VERIFICATION,
        },
        () => this.verifyPhone()
      )
      return
    }

    const userInfo = await verifyPhone.status({ phone: formatPhone.e164(phone) })
    if (userInfo?.verified && userInfo?.email) {
      this.queryString = { ...this.queryString, ...{ email: userInfo.email } }
      this.setState(
        {
          status: STATUS_PHONE_VERIFICATION,
        },
        () => this.verifyPhone()
      )
      return
    }

    this.setState({ status: STATUS_EMAIL })
  }

  handlePhoneFormSubmit = async (e) => {
    e.preventDefault()
    const { phone } = this.state
    const { email } = this.queryString
    this.setState({ signInLoading: true })

    if (email) {
      this.verifyPhone()
      return
    }

    const userInfo = await verifyPhone.status({ phone: formatPhone.e164(phone) })
    if (userInfo?.email) {
      this.queryString = { ...this.queryString, ...{ email: userInfo.email } }
      this.verifyPhone()
    } else {
      this.setState({
        signInLoading: false,
        status: STATUS_EMAIL,
      })
    }
  }

  handlePhoneVerificationFormBack = () => {
    this.setState({ status: STATUS_PHONE })
  }

  handleSendNewPhoneVerificationCode = () => {
    this.setState(
      (prevState) => ({
        phoneVerificationCode: '',
        phoneVerificationResendCodeCount: prevState.phoneVerificationResendCodeCount++,
      }),
      () => {
        reporter.trackEvent(events.PHONE_VERIFICATION_RESEND, {
          phoneVerificationResendCodeCount: this.state.phoneVerificationResendCodeCount,
        })
      }
    )

    this.verifyPhone()
    Alert.info('Code sent')

    if (this.phoneVerificationForm) this.phoneVerificationForm.focusInput()
  }

  handlePhoneVerificationFormSubmit = (e) => {
    e.preventDefault()
    const { blid } = this.props
    const { phone, phoneLast4, phoneVerificationCode } = this.state
    const { email } = this.queryString

    this.setState({ signInLoading: true })

    verifyPhone.submit({
      phone: phone && formatPhone.e164(phone),
      phoneLast4,
      phoneVerificationCode,
      params: {
        blid,
        email,
      },
      onError: (validation) => {
        this.setState({
          phoneVerificationValidation: validation,
          signInLoading: false,
        })

        if (this.phoneVerificationForm) this.phoneVerificationForm.focusInput()
      },
      onSuccess: ({ user }) => {
        this.context.updateUser({ user: true })
        this.setState({ signInLoading: false })

        if (!this.userExists) {
          reporter.analyticsSignUp(user.user_id)
        }

        reporter
          .analyticsSignIn({
            blid,
            sub: user.user_id,
            $email: email,
            $phone: formatPhone.e164(user.phone),
          })
          .setErrorUser({
            email,
            phone: formatPhone.e164(user.phone),
            sub: user.user_id,
          })

        if (!this.userExists) {
          reporter.trackEvent(events.SIGN_UP, {
            blid,
            email,
            phone: formatPhone.e164(user.phone),
          })
        }

        reporter.trackEvent(events.PHONE_VERIFICATION_SUCCESS, {
          blid,
          email,
          phone: formatPhone.e164(user.phone),
        })

        this.navigate()
      },
    })
  }

  handleEmailFormSubmit = (e) => {
    e.preventDefault()
    this.queryString = { ...this.queryString, ...{ email: this.state.email } }
    this.setState(
      {
        status: STATUS_PHONE_VERIFICATION,
      },
      () => this.verifyPhone()
    )
  }

  navigate = () => {
    navigate(
      `${this.redirectPath}?${qs.stringify({
        blid: this.props.blid,
        email: this.queryString.email,
        phone: formatPhone.e164(this.state.phone),
      })}`
    )
  }

  verifyPhone = async () => {
    const { phone } = this.state
    const { blid } = this.props
    const { email } = this.queryString

    const commonArgs = {
      params: {
        blid,
        email,
      },
      phone,
      onSuccess: () => {
        this.setState({
          signInLoading: false,
          status: STATUS_PHONE_VERIFICATION,
        })
      },
    }

    let phonePresent = false

    if (await verifyPhone.status({ phone: formatPhone.e164(phone) })) {
      phonePresent = true
      this.userExists = true
    }

    if (!phonePresent && !blid) {
      this.setState({ signInLoading: false })
      navigate(paths.FORBIDDEN)
    } else {
      verifyPhone.verify({
        ...commonArgs,
        signUp: phonePresent ? undefined : true,
        onError: ({ validation }) => {
          this.setState({ signInLoading: false })

          if (validation) {
            notifications.notify({
              level: 'error',
              message: validation,
            })
            this.setState({ status: STATUS_PHONE })
          }
        },
      })
    }
  }

  renderPhoneForm() {
    const { phone, phoneValid, signInLoading } = this.state

    return (
      <form onSubmit={this.handlePhoneFormSubmit}>
        {/* TODO: FieldPhone */}
        <FieldWithInput
          input={InputPhoneNumber}
          inputProps={{
            autoComplete: 'tel-national',
            autoFocus: true,
            //inputRef: (el) => { this.phoneInput = el },
            name: 'phone',
            value: phone,
            testid: 'sign-in-form--phone-input',
          }}
          label="Mobile Phone"
          single
          validate={(e) => {
            assertions.required(e.target.value)
            assertions.phoneNumber(e.target.value)
          }}
          onInvalidChange={(e) => {
            this.setState({
              phone: e.target.value,
              phoneValid: false,
            })
          }}
          onValidChange={(e) => {
            this.setState({
              phone: e.target.value,
              phoneValid: true,
            })
          }}>
          <Button
            disabled={!phoneValid}
            loading={signInLoading}
            level="primary"
            type="submit"
            testid="sign-in-form--submit-button"
            onClick={this.handlePhoneFormSubmit}>
            Sign In
          </Button>
        </FieldWithInput>
      </form>
    )
  }

  renderPhoneVerificationForm() {
    const {
      phone,
      phoneVerificationCode,
      phoneVerificationCodeValid,
      phoneVerificationResendCodeCount,
      phoneVerificationValidation,
      signInLoading,
    } = this.state

    return (
      <form onSubmit={this.handlePhoneVerificationFormSubmit}>
        <Fieldset
          title="Check your phone for a text message"
          copy={
            <p>
              We sent a 4-digit code to <Text weight="bold">{phone}</Text>.
              {phoneVerificationResendCodeCount < inputValues.PHONE_VERIFICATION_RETRY_MAX && (
                <>
                  <br />
                  Didn’t get a code? {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    color="blue"
                    decorated={false}
                    onClick={this.handleSendNewPhoneVerificationCode}>
                    Resend code
                  </Link>
                  .
                </>
              )}
            </p>
          }>
          {/* TODO: Field* */}
          <FieldWithInput
            error={phoneVerificationValidation}
            input={Input}
            inputProps={{
              autoComplete: 'one-time-code',
              autoFocus: true,
              inputRef: (el) => {
                this.phoneVerificationCodeInput = el
              },
              maxLength: inputValues.PHONE_VERIFICATION_CODE_LENGTH,
              placeholder: '1234',
              type: 'tel',
              value: phoneVerificationCode,
              testid: 'sign-in-form--code-input',
            }}
            label="Code"
            single
            validate={(e) => {
              assertions.required(e.target.value)
              assertions.integer(e.target.value)
              assertions.length(e.target.value, inputValues.PHONE_VERIFICATION_CODE_LENGTH)
            }}
            onInvalidChange={(e) =>
              this.setState({
                phoneVerificationCode: e.target.value,
                phoneVerificationCodeValid: false,
              })
            }
            onValidChange={(e) =>
              this.setState({
                phoneVerificationCode: e.target.value,
                phoneVerificationCodeValid: true,
                phoneVerificationValidation: '',
              })
            }>
            <Button
              disabled={!phoneVerificationCodeValid}
              level="primary"
              loading={signInLoading}
              type="submit"
              testid="sign-in-form--phone-verification-code-submit-button"
              onClick={this.handlePhoneVerificationFormSubmit}>
              Sign In
            </Button>
          </FieldWithInput>
        </Fieldset>
      </form>
    )
  }

  renderEmailForm() {
    const { email, emailValid } = this.state
    return (
      <form onSubmit={this.handleEmailFormSubmit}>
        <Fieldset title="Add your email">
          <FieldEmail
            value={email}
            onInvalidChange={(e) => {
              this.setState({
                email: e.target.value,
                emailValid: false,
              })
            }}
            onValidChange={(e) => {
              this.setState({
                email: e.target.value,
                emailValid: true,
              })
            }}
          />
          <Button
            disabled={!emailValid}
            level="primary"
            type="submit"
            testid="sign-in-form--submit-email-button"
            onClick={this.handleEmailFormSubmit}>
            Next
          </Button>
        </Fieldset>
      </form>
    )
  }

  render() {
    const { className } = this.props
    const { status } = this.state

    return (
      <div className={classNames('SignInForm', className)}>
        {status === STATUS_PHONE && this.renderPhoneForm()}
        {status === STATUS_EMAIL && this.renderEmailForm()}
        {status === STATUS_PHONE_VERIFICATION && this.renderPhoneVerificationForm()}
      </div>
    )
  }
}

SignInForm.propTypes = {
  blid: PropTypes.string,
  className: PropTypes.string,
}

export default SignInForm
